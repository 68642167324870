/* ========================================

    schedule
        

    INDEX
        - 
        - 
        - 

======================================== */

/* ========================================
    カテゴリー

    1 => "ONEMAN"
    2 => "INSTORE"
    3 => "EVENT"
    5 => "OTHERS"
    6 => "OUTSTORE"
        
======================================== */

$C_ONEMAN : #b10001;
$C_INSTORE : #1d82ac;
$C_EVENT : #8f8f8f;
$C_OTHERS :#cecece;
$C_OUTSTORE : #1d82ac;


.cat_back{
    padding: 2px 5px;
    color: #fff;

    &--1{
        background-color: $C_ONEMAN;
    }
    &--2{
        background-color: $C_INSTORE;
    }
    &--3{
        background-color: $C_EVENT;
    }
    &--5{
        background-color: $C_OTHERS;
    }

    &--6{
        background-color: $C_OUTSTORE;
    }
}


.schedule_list{
    .cat_back{
        margin: 0;
    }

    .schedule{
        &__item{
            display: flex;
            align-items: center;
        }
        &__left{
            width: 20%;
            text-align: center;
            border-right: 1px solid #e4e4e4;
            padding-right: 10px;

        }
        &__right{
            width: 70%;
            padding-left: 10px;
            height: auto !important;
            font-size: 90%;
            text-indent: 0px !important;
            background: none;
            color: #000;
            font-family: メイリオ,Helvetica,"ＭＳ Ｐゴシック",Osaka,"ヒラギノ角ゴ Pro W3";
        }
    }
}

#liveschedule{
    .schedule_list{
        margin: 0;

    }
}