@charset "utf-8";
/* CSS Document */

/***************************************************************

 common settings

****************************************************************/

body{
	margin: 0 auto;
	padding: 0;
	line-height:1.4;
	background:#ffffff;
	font-family: Georgia,メイリオ,Helvetica,"ＭＳ Ｐゴシック",Osaka,"ヒラギノ角ゴ Pro W3";
	font-size:0.9em;
	color: #555555;
	-webkit-text-size-adjust:none;
	-webkit-tap-highlight-color:rgba(255,204,255,0.4);
}
h1{
	/*font-size: 140%;*/
        color: #EB6088;
        /*clear: both;*/
}
h1,h2,h3,h4,h5,h6{
	margin: 0;
	padding: 0;
        text-indent: 0 !important;
}

p{
	line-height: 1.5em;
}


ul,ol,li,dl,dt,dd{
	list-style: none;
	margin: 0;
	padding: 0;
}


figure,figcaption,hgroup,article,section,header,footer,nav{
	display: block;
	margin: 0;
	padding: 0;
}
img{
	border: 0;
}
a{
	text-decoration: none;
	color: #eb6088;
}
.cf:after{
	content: "";
	display: block;
	clear: both;
	float: none;

}
img.blank{
	border: none !important;
	box-shadow: none !important;
}

.dotted{
	border-width: 1px 0 0 0;
	border-style: dotted;
	border-color: #fff;
	height: 1px;
	margin: 1em 0;
}


.line {
	display: block;
	border-bottom: solid 1px #fff;
}

section.ticket_list article,
section.news_list article{
 /*border-top: 1px solid #E4E4E4;*/
 border-bottom: 1px solid #E4E4E4;
/* background: url("../img/information_arrow.jpg") no-repeat scroll right center #FFFFFF;*/
       height: auto;
       /*line-height: auto;
       vertical-align: middle;*/
       text-indent: 0 !important;
       word-wrap: break-word;
}

section.news div{
	padding:5px 10px 0px 10px;
}

/*section.news_list h1{
   height: 52px;
   vertical-align: middle;
   line-height: 52px;
}*/


/***************************************************************

 page settings

****************************************************************/

/* ---------------------- header, footer  ------------------------ */
body.page header:before,
footer:before{
/*	content:"";*/
	display: block;
	padding: 0;
	margin: 0 1px 2px;
/*	background-image: url(../img/line.png);*/
	height: 6px;
	font-color: #ffffff;
}

#header {
	clear: both;
	background: #999999;
	margin: 0;
	padding: 0.5em 0 0.5em;
	text-align: center;
}

#header a{
	color: #fff;
}

#index_footer {
	clear: both;
	background: #000;
	margin-bottom: 2em;
	text-align: center;
	font-size: 80%;
	color: #fff;
	letter-spacing: 0;
	word-spacing: 0;
}

.page_top a {
	clear: both;
	float:right;
	color:#555555;
	font-size:90%;
	padding-top: 2em;
	margin: 0;
}


.footer_home {
	clear: both;
	background: #000;
	margin-bottom: 2em;
	text-align: center;
	font-size: 80%;
	color: #fff;
	letter-spacing: 0;
	word-spacing: 0;
}

.footer_home div.sitenavi{
	padding: 0;
}

.footer_home div.sitenavi:after{
	content: "";
	display: block;
	clear: both;
	float: none;
}

.footer_home div.sitenavi a{
	display: block;
	color: #fff;
}

.footer_home div.sitenavi a:first-child{
	float: left;
	padding: 2em 20px 0 20px;
	text-align: left;
	background: url(../img/icon_home.png) left bottom no-repeat;
}

.footer_home div.sitenavi a:last-child{
	float: right;
	padding: 2em 0 0 20px;
	text-align: right;
}

.more_btn a{
	float: right;
	background-color: #ff6699;
	display: block;
	width: 92px;
	height: 32px;
	line-height: 32px;
	color: #fff;
	text-align: center;
	margin: 8px 6px 4px 10px;
	border-top: 1px solid #DDD;
	border-left: 1px solid #DDD;
	border-right: 1px solid #BBB;
	border-bottom: 1px solid #BBB;
}

body.page header h1:after{
	content:"";
	display: block;
	margin: -11px 0 0 0;
        /*background-image: url(/img/base_banner.png);*/
	background-position: left bottom;
	background-repeat: repeat-x;
	height: 52px;
}
body.page header h1{
	height: 34px;
}



#index header h1{
	text-align: center;
}
#index header h1 img{
	display: block;
}

/* header_nav */
#index header nav.header_nav li{
	display: block;
	background: #000;
	/*border-bottom: solid 1px #3f3f3f;*/
}
#index header nav.header_nav li a{
	display: block;
	line-height: 3em;
	text-align: center;
	color: #fff;
}
#index header nav.header_nav li a img{
	margin: 0 5px 0 0;
	background: #fff;
}

/* @group toppage */

#topimage {
	clear: both;
	text-align: center;
	margin: 0;
	padding: 0;
	height: auto;
	background: #ffffff;
	overflow: hidden;
    background: url("../img/bg_topimg.png") repeat scroll 0 0 #FFFFFF;
}

#topimage img {
	margin: 0;
	padding: 0;
    border: 0;
    width: 100%;
    vertical-align: top;
}


section.top_btn {
	margin: 10px;
}


a.regist {
	width: 47%;
	height: 40px;
	float: left;
	color: #fff;
	line-height: 40px;
	text-align: center;
	font-size: 90%;
	font-weight: bold;
	letter-spacing: 0.1em;
	background: none repeat scroll 0 0 #555555;
	border: 1px solid #898989;
	border-radius: 5px 5px 5px 5px;
	margin: 0 1px 5px 0;
	display:block;
}

a.login {
	width: 47%;
	height: 40px;
	float: right;
	background: #999999;
	color: #fff;
	line-height: 40px;
	text-align: center;
	font-size: 90%;
	font-weight: bold;
	letter-spacing: 0.1em;
	background: none repeat scroll 0 0 #555555;
	border: 1px solid #898989;
	border-radius: 5px 5px 5px 5px;
	margin: 0 2px 5px 0;
	display:block;
}

.marquee {
	overflow: -webkit-marquee;
	width: 100%;
	color: #000;
	background-color: #d6d2c7;
	border-top: 1px double #fff;
	border-bottom: 1px double #fff;
}

.member_id {
	text-align: right;
}

/* @end */



/* @group menulist */

#menulist {
	clear: both;
	text-align: left;
	margin: 0;
	padding: 0;
	background: #000;
	overflow: auto;
}


/*.list_a a {
	color: #555555;
	width: 100%;
	font-size: 18px;
	text-align: left;
	font-weight: bold;
	font-family: Georgia;
	display:block;
	margin: 0;
	padding: 0.2em 0;
	line-height: 200%;
        height: 52px;
        line-height: 46px;
        vertical-align: middle;
	/*background: #000 url(../img/acidsp_rectangle.png) no-repeat left center
};*/


.list_b a {
	color: #555555;
	width: 100%;
	font-size: 100%;
	text-align: left;
	font-weight: bold;
	font-family: Georgia;
	display:block;
	margin: 0;
	padding: 0.2em 0;
	line-height: 200%;
	background: #fff url(../img/arrow_news.png) no-repeat right center;
	height: 52px;
	line-height: 52px;
	vertical-align: middle;
	border-bottom:solid 1px #dedede;
}

/*.list_line_a {
	width: 100%;
	border-bottom:solid 1px #dedede;
}*/

.list_line_b {
	width: 100%;
	border-bottom:solid 1px #dedede;
}

/* @end */


/* @group bioimg */

#bioimg {
	clear: both;
	text-align: center;
	margin: 0;
	padding: 0;
	background: url(../img/profile/arttop_1.png);
	overflow: visible;
}

#bioimg img {
	margin: 0;
	padding: 0;
	border: 0;
}


#bioimg2 {
	clear: both;
	text-align: center;
	margin: 0;
	padding: 0;
	background: url(../img/bg_topimg.png);
	overflow: visible;
}

#bioimg2 img {
	margin: 0;
	padding: 0;
	border: 0;
}

.bio_member {
	background: -moz-linear-gradient(top,#FFF 0%,#EEE);
	background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE));
	width: 50%;
	padding: 5%;
	text-align: center;
	margin: 2% auto;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
}

.bio_member a{
	color: #000;
	font-weight: bold;
	padding: 0;
}

.center  {
	text-align: center;

}

/* @end */


section.banner {
	margin: 10px;
}


.banner {
/*	margin: 4px auto 2px;*/
	clear:both;
	padding: 2px;
	line-height: 1.2em;
	text-align: center;
}

.banner a {
	text-decoration: underline;
}

.banner img {
	margin: 4px auto 2px;
	background: #efefef;
	-webkit-box-shadow: 0 0 10px rgba(85,85,85,0.4);
	-moz-box-shadow: 0 0 10px rgba(85,85,85,0.4);
	-ms-box-shadow: 0 0 10px rgba(85,85,85,0.4);
	-o-box-shadow: 0 0 10px rgba(85,85,85,0.4);
	box-shadow: 0 0 10px rgba(85,85,85,0.4);
}

/* bread_crumb */
nav.bread_crumb,
.breadCrumb{
	padding: 7px 10px 7px 10px;
}
nav.bread_crumb li,
.breadCrumb li{
	display: inline;
}
nav.bread_crumb li:after,
.breadCrumb li:after{
	display: inline;
	content: url(../img/arrow_bread.png);
	width: 11px;
	height: 10px;
	padding: 0 5px;
}
nav.bread_crumb li:last-child:after,
.breadCrumb li:last-child:after{
	display: none;
	content: "";
}
nav.bread_crumb,
.breadCrumb{
	color: #545454;
	font-size: small;
}

.bread_crumb a:link {/*未訪問のリンク*/
    color: #898989;
    text-decoration: none;
}

.bread_crumb a:visited {/*訪問後のリンク*/
    color: #898989;
    text-decoration: none;
}

.bread_crumb a:hover {/*マウスオン*/
    color: #555555;
    text-decoration: none;
}


/* main_nav */
header nav.main_contents{
	margin: 1px;
}
header nav.main_contents ul{
	display: block;
	padding: 5px 7px;
	text-align: center;
}
header nav.main_contents li{
	display: inline;
        font-family: Georgia;
        font-size: 13px;
}

header nav.main_contents a:link {/*未訪問のリンク*/
    color: #898989;
    text-decoration: none;
}

header nav.main_contents a:visited {/*訪問後のリンク*/
    color: #898989;
    text-decoration: none;
}

header nav.main_contents a:hover {/*マウスオン*/
    color: #555555;
    text-decoration: none;
}

#index header nav.main_contents{
	padding-bottom: 3px;
}
#index header nav.main_contents h2{
	height: 40px;
	text-indent: -9999em;
}
#index header nav.main_contents ul{
	margin: 3px 2px 0px;
	-webkit-border-radius: 5px;
	background-image: url(/img/base_banner_01.png);
}
#index header nav.main_contents li a{
	padding: 5px 13px;
}
#news header nav.main_contents{
	background: #ffffff;
}
#news header nav.main_contents ul{
	padding: 7px;
}
#news header nav.main_contents li a{
	padding: 5px 6px;
}





/* ---------------------- list ------------------------ */
section.discography_list {
	border-bottom: solid 1px #e4e4e4;
}

section.discography_list a
section.calendar_list a
section.movie_list a
section.information a{
	display: block;
	padding: .5em 0;
/*	border-bottom: solid 1px #e4e4e4;*/
}

section.news_list a{
	display: block;
	/*padding: .10em 0;*/

/*	border-bottom: solid 1px #e4e4e4;*/
}

section.ticket_list a{
	display: block;
/*	border-bottom: solid 1px #e4e4e4;*/
}



#liveschedule h1,
section.news_list h1.b01{
	font-family: Georgia;
	font-size: 20px;
	font-weight: bold;
	color: #eb6088;
	background-color: #000000;
	background-image: url(/img/base_banner_01.png);
	background-position:left top;
	background-repeat: no-repeat;
	height: 45px;
	padding-left: 3px;
	/*line-height: 52px;
	vertical-align: middle;*/
	white-space: nowrap;
	clear: both;
	-webkit-box-shadow: 0 0 2px 0 #666;
	-moz-box-shadow: 0 0 2px 0 #666;
	box-shadow: 0 0 2px 0 #666;
}

#liveschedule h1,
section.news_list h1.b02{
	font-family: Georgia;
	font-size: 20px;
	font-weight: bold;
	color: #eb6088;
	background-color: #000000;
	background-image: url(/img/base_banner_02.png);
	background-position:left top;
	background-repeat: no-repeat;
	height: 45px;
	padding-left: 3px;
	/*line-height: 52px;
	vertical-align: middle;*/
	white-space: nowrap;
	clear: both;
	-webkit-box-shadow: 0 0 2px 0 #666;
	-moz-box-shadow: 0 0 2px 0 #666;
	box-shadow: 0 0 2px 0 #666;
}


.textp{
	margin-left:69px;
	display:block;
}


section.ticket_list a,
section.schedule_list a{
	display: block;
	padding: .5em 0;
	border-bottom: solid 1px #e4e4e4;
}


section.news_list article,
section.discography_list article
section.calendar_list article
section.movie_list article
section.schedule_list article
section.information article{
	display: block;
	margin: 0 10px 0 ;
	line-height: 1.5em;
	background-position: right center;
	background-repeat: no-repeat;
	/*border-bottom: solid 1px #e4e4e4;*/
}

section.discography_list article{
        border-top: solid 1px #e4e4e4;
}


section.ticket_list article,
section.calendar_list ul,
section.movie_list ul{
        border-top: solid 1px #e4e4e4;
}

section.calendar_list article,
section.movie_list article{
        border-bottom: solid 1px #e4e4e4;
}


#news.list section.news_list{
	/*border-top: solid 1px #e4e4e4;*/
}


/* news_list */
section.news_list article h1{
	height: auto !important;
	font-size: 100%;
	text-indent: 0px !important;
	background: none;
	color: #555555;
	padding: 0.5em;
        white-space: normal;
        padding: 10px 20px 10px 10px;
}


section.ticket_list article h1{
    color: #555555;
    font-family: メイリオ,Helvetica,"ＭＳ Ｐゴシック",Osaka,"ヒラギノ角ゴ Pro W3";
    height: auto;
    padding: 10px 20px 10px 10px;
}

section.ticket_list article h1:after,
section.news_list article h1:after{
	background: none !important;
}

section.news_list article time{
	font-weight: bold;
	color: #898989;
	font-size: small;

}


/* schedule_list */
section.schedule_list a{
	display: block;
	padding: .5em 0;
/*	border-bottom: solid 1px #e4e4e4;*/
}


section.schedule_list a{
	display: block;
	padding: .5em 0;
	border-bottom: solid 1px #e4e4e4;
}


section.schedule_list article{
	display: block;
	margin: 0 10px 0 ;
	line-height: 1.5em;
	background-position: right center;
	background-repeat: no-repeat;
}


#schedule.list section.schedule_list{
	border-top: solid 1px #e4e4e4;
}

section.schedule_list article h1{
	height: auto !important;
	font-size: 100%;
	text-indent: 0px !important;
	background: none;
	color: #000;
	padding-right: 5px;
}

section.schedule_list article h1:after{
	background: none !important;
}

section.schedule_list article time{
	color: #000;
}


/* diary */

.diary_title {
	text-align: center;
	font-weight: bold;
	font-size: 120%;
	margin: 10px;
}

.diary_day {
	text-align: left;
	font-size: 80%;
}

.diary_body {
	text-align: left;
	font-size: 100%;
	margin: 20px 0;
}

section.diary_list article {
	height: auto !important;
	font-size: 100%;
	text-indent: 0px !important;
	background: none;
	color: #fff;
	padding: 0.5em;
	margin: 0 0 0.5em;
	border-bottom:1px solid #fff;
}

section.diary_list article h1{
	background: none;
	color: #fff;
	font-size: 95%;
	margin: 0;
	background-position: right center;
	background: url(../img/arrow_news.png) right center no-repeat;
}

section.diary_list article h1:after{
	background: none !important;
}

section.diary_list article time{
	font-weight: bold;
	color: #f00;
}

section.gallery p{
	margin-top: 20px;
	font-size: 80%;
}



section.message {
	margin: 20px 10px;
}

section.support {
	margin: 20px 10px;
	clear: both;
}

section.support p{
	clear: both;
}


/* store */

.store_top {
	margin: 0 auto;
	text-align: center;
}

.store_list h1{
	font-weight: bold;
	font-size:90%;
}

.store_list ul{
	margin: 0px;
	border-bottom: solid 1px #a6a6a6;
}

.store_list ul li{
	width: 96%;
	vertical-align: middle;
	margin: 0px;
}

.store_list ul li a{
	height:100px;
	color: #fff;
	padding: 2%;
	white-space: normal;
	display: block;
	background: url(../img/arrow_news.png) no-repeat right center;
}

.store_list ul li img{
	float: left;
	margin-right: 5%;
}

.store_detail_img {
	clear: both;
	margin: 10px auto;
}

.btn_store_big {
	font-size: 100%;
	font-weight: bold;
	width: 250px;
	height: 40px;
	margin: 10px auto;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-moz-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-webkit-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	text-align: center;
	margin: 0 auto;
	display:block;
}


.btn_store_small {
	font-size: 80%;
	font-weight: bold;
	width: 150px;
	height: 40px;
	margin: 10px auto;
}

.btn_store_smallsmall {
	font-size: 80%;
	font-weight: bold;
	width: 80px;
	height: 30px;
	margin: 10px auto;
}

.stock_ok {
	color: #98fb98;
}

.stock_ng {
	color: #ff0000;
}

.stock_little {
	color: #ffff00;
}

.attention_h1 {
	font-size:90%;
	text-align: center;
	color:#ffd700;
}


.attention {
	font-size:70%;
}

.attention_center {
	font-size:70%;
	text-align: center;
}

section.news article {
	word-break: break-all;
}


section.news article time{
	font-weight: bold;
	color: #555555;
}


section.news article h1{
	font-weight: bold;
	color: #000;
	margin: 0 0 20px;
	line-height:30px;
}

.red {
	color: #ff0000;
}



/* invitation */

section.invitation_list ul {
	height: auto !important;
	text-indent: 0px !important;
	background: none;
	color: #fff;
	padding: 0.5em;
	margin: 0 0 0.5em;
	border-bottom:1px solid #fff;
}

section.invitation_list ul li {
	height: auto !important;
	font-size: 90%;
}

section.invitation_list ul li a {
	height: auto !important;
	color: #fff;
	white-space: normal;
	display: block;
	background: url(../img/arrow_news.png) no-repeat right center;
}

section.invitation_list ul li h1{
	font-weight: bold;
}


/* qanda */

.qanda_answer {
	color: #6495ed;
	font-size: 120%;
	font-weight: bold;
	margin:0 0 10px;
}

.qanda_name {
	text-align: right;
	margin: 10px 0;
}

/* schedule */




/* message */

.error {
	color: #ff0000;
	font-weight:bold
}


input.msg_send{
	float: center;
	background: -moz-linear-gradient(top,#FFF 0%,#EEE);
	background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE));
	width: 80%;
	height: 50px;
	font-size: 100%;
	font-weight:bold;
	margin: 10px auto;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-moz-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-webkit-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
}

textarea.input_msg{
    background: #fff;
    padding: 5px 8px;
    border: 1px solid #dfdfdf;
    color: #242424;
    font-family: georgia, serif;
    font-size: 1.2em;
    margin: 0 0 10px 0;
    width:235px;
    height:150px;
}



/* messagemovielist */


.messagemovielist_list h1{
	font-weight: bold;
	font-size:90%;
}

.messagemovielist_list ul{
	margin: 0px;
	border-bottom: solid 1px #a6a6a6;
}

.messagemovielist_list ul li{
	width: 100%;
	vertical-align: middle;
	margin: 0px;
}

.messagemovielist_list ul li a{
	height:100px;
	color: #fff;
	padding: 2%;
	white-space: normal;
	display: block;
	background: url(../img/arrow_news.png) no-repeat right center;
}

.messagemovielist_list ul li img{
	float: left;
	clear:both;
	vertical-align: middle;
	margin-right: 2%;
	border: 1px solid #fff;
}



.messagemovie_title {
	font-weight: bold;
	text-align: center;
	font-size: 130%;
	color: #fff;
	margin: 15px;
}

.movie_caption {
	color: #fff;
	font-size: 100%;
	margin: 0 0 40px;
}


.millvi {
	text-align: right;
	font-size: 75%;
	color: #fff;
	margin: 0;
}


/* userprofile */


.userprofile_item {
	font-weight: bold;
	font-size: 130%;
}

.userprofile_attention {
	color: #FF0000;
	font-size: 60%;
}


/* special
#special {
	text-align: center;
	margin: 0;
	padding: 0;
}
.banner {
	margin: 8px auto;
	padding: 2px;
	line-height: 1.2em;
        text-align: center;
}
.special_title {
	color: #ff0000;
	text-align: center;
	font-weight: bold;
	font-size: 150%;
	margin: 10px 0;
}

.special_h2 {
	font-size: 120%;
	margin: 20px 0;
}

.special_body {
	font-size: 70%;
	line-height: 1.7em;
}
*/

/* bdmail */

.bdmail_h3 {
	text-align: center;
	font-weight: bold;
	font-size: 130%;
}


/* news */
#index section.news_list h1 {
	color: #000;
	width: 100%;
	font-size: 130%;
	text-align: left;
	font-weight: bold;
	font-family: "Helvetica";
	background: -moz-linear-gradient(left, #fbfbfb, #6c6a6a);
	background: -webkit-gradient(linear, left top, right  bottom, from(#fbfbfb), to(#6c6a6a));
}

#index section.news_list h1 a{
	color: #000;
	text-align: left;
}

.news_list_list {
	color: #555555;
        font-family: メイリオ,Helvetica,"ＭＳ Ｐゴシック",Osaka,"ヒラギノ角ゴ Pro W3";
       height: auto;
       padding: 10px 20px 10px 10px;
}


#link_right a{
	float:right;
	color: #fff;
	font-size:140%;
	background-position: right center;
	/*background: url(../img/r_arrow.png) right center no-repeat;*/
	display: block;
	/*padding-right: 20px;*/
	line-height: 1.5em;
	/*margin: 0 10px;*/
        padding: 5px 0px 5px 0px;
        width: 108px;
        height: 43px;

}

.right_right a{
	float:right;
	color:#fff;
	font-size:70%;
	padding-bottom: 2em;
	margin: 0;
}


/*
section.news_list corner_title{
	color: #fff;
	width: 100%;
	font-size: 130%;
	text-align: right;
	font-weight: bold;
	font-family: "Helvetica";
	display:block;
	margin: 0;
	padding: 0.2em 0;
	line-height: 200%;
	background: #000 url(../img/acidsp_rectangle.png) no-repeat left center;
}
*/


/* news_category */

.news_category a{
	color:#000;
	font-size:80%;
}

#news_category {
	clear: both;
	margin: 3px 0 10px;
	padding: 0;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
}

#news_category_wrap {
	background: -moz-linear-gradient(top,#FFF 0%,#EEE);
	background: -webkit-gradient(linear, left top, left bottom, from(#EEE), to(#898989));
	border: 1px solid #000000;
	margin: 0 auto;
	padding: 0;
	text-align: center;
}

#news_category ul {
	margin: 0;
	overflow: hidden;
}

#news_category ul li {
	border-right: 1px solid #000000;
	float: left;
	text-align: center;
}

#news_category ul li a {
	background: -moz-linear-gradient(top,#FFF 0%,#EEE);
	background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE));
	color: #000;
	display: block;
	font-size: 80%;
	line-height: 2.6em;
	overflow: hidden;
	padding: 0 0.94em;
	text-decoration: none;

}



/* discography_list */
section.discography_list article h1{
	height: auto !important;
	font-size: 90%;
　　　　font-family: Georgia;
	text-indent: 0px !important;
	background: none;
	color: #555555;
	font-family: Georgia,メイリオ,Helvetica,"ＭＳ Ｐゴシック",Osaka,"ヒラギノ角ゴ Pro W3";
	line-height: auto;
	vertical-align: middle;
}

section.discography_list article time{
	height: auto !important;
	font-size: 85%;
　　　　font-family: Georgia;
	text-indent: 0px !important;
	background: none;
	color: #555555;
        font-family: Georgia,メイリオ,Helvetica,"ＭＳ Ｐゴシック",Osaka,"ヒラギノ角ゴ Pro W3";
}


section.discography_list article figure{
	float: left;
	width: 60px;
	padding: 0 15px 0 0;
	/*border-bottom: solid 1px #e4e4e4;*/
}


section.discography_dtl article h1{
	height: auto !important;
	font-size: 120%;
	text-align: center;
	text-indent: 0px !important;
	background: none;
	color: #fff;
}

section.discography_dtl article time{
	height: auto !important;
	font-size: 100%;
	text-align: center;
	text-indent: 0px !important;
	background: none;
	color: #fff;
}


section.discography_dtl article figure{
	margin: 0 auto;
	padding: 5%;
	text-align: center;
}

section.discography_dtl article figure img{
	border: solid 1px #e4e4e4;
	margin: 0 auto;
}


.disco_list {
	margin: 10px auto;
	padding: 0;
	font-family: sans-serif;
	line-height: 1.4em;
}

.disco_list img {
	float: left;
	border: 1px solid #fff;
	padding: 0;
	margin: 0 10px 0 0;
}

.disco_list ul {
	text-align: left;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #fff;
}

.disco_list li {
	text-align: left;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #fff;
	line-height: 1.4em;
	background: url(../img/arrow_news.png) no-repeat right center;
}

.disco_list li a {
	display: table;
	width: 100%;
	color: #fff;
	text-decoration: none;
	padding: 12px 25px 12px 10px;
	clear:both;
}

.disco_list li strong {
	font-weight: bold;
	font-size: 110%;
}


#disco.page nav.category ul {
	padding: 0;
}

#disco.page nav.category ul li{
	margin: 23px auto;
	background-color: #555555;/*-moz-linear-gradient(top,#FFF 0%,#EEE);*/
	/*background: /-webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE))*/;
	width: 70%;
	height: 50px;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-moz-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-webkit-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
}
#disco.page nav.category ul li a{
	color: #ffffff;
	font-size: 130%;
        font-family: Georgia;
	padding: 10px 0;
	display: block;
	vertical-align: middle;
	text-align: center;

}


.disco_categorytitle {
	margin: 0 auto;
	background-color: #d6d2c7;
	text-align: center;
	font-size: 100%;
	color: #000;
}


section.movie_list article h1{
	height: auto !important;
	font-size: 95%;
	text-indent: 0px !important;
	background: none;
	color: #555555;
	font-family: Georgia,メイリオ,Helvetica,"ＭＳ Ｐゴシック",Osaka,"ヒラギノ角ゴ Pro W3";
	line-height: auto;
	vertical-align: middle;
}

section.movie_list article time{
	height: auto !important;
	font-size: 85%;
	text-indent: 0px !important;
	background: none;
	color: #555555;
        font-family: Georgia,メイリオ,Helvetica,"ＭＳ Ｐゴシック",Osaka,"ヒラギノ角ゴ Pro W3";
}


section.movie_list article{
	margin: 5px 0 0 5px;
}

section.movie_list article figure{
	float: left;
	width: 100px;
	padding: 0 15px 0 0;
/*	margin: 5px 0 0 5px;*/
}


section.movie_list h2 {
	padding: 15px 0;
	text-align: center;
	border-top: solid 1px #dbdbdb;
	border-bottom: solid 1px #dbdbdb;
	background: -webkit-gradient( linear, left top, left bottom, from(#fff), to(#d7d7d7));
	display: block;
	font-weight: bold;
	margin-bottom: 15px;
}

section.movie_list p {
	margin: 5px 0 5px 10px;
}




section.calendar_list article h1{
	height: 80px ;
	font-size: 95%;
	text-indent: 0px ;
	background: none;
	color: #555555;
	font-family: Georgia,メイリオ,Helvetica,"ＭＳ Ｐゴシック",Osaka,"ヒラギノ角ゴ Pro W3";
	line-height: 80px;
	vertical-align: middle;
}

section.calendar_list h2 {
	padding: 15px 0;
	text-align: center;
	border-top: solid 1px #dbdbdb;
	border-bottom: solid 1px #dbdbdb;
	background: -webkit-gradient( linear, left top, left bottom, from(#fff), to(#d7d7d7));
	display: block;
	font-weight: bold;
	margin-bottom: 15px;
}

section.calendar_list article{
	margin: 5px 0 0 5px;
}

section.calendar_list article figure{
	float: left;
	width: 60px;
	margin: 5px 0 0 5px;
}


section.calendar_list p {
	font-size: 80%;
	margin: 5px 0 5px 10px;
	text-align: center;
}

section.calendar_list .img_a{
	text-align: center;
	width: 80%;
	margin: 0 auto;
}



/* profile */
#profile section ul{
	width: 320px;
	margin: 30px auto 10px;
}
#profile section ul li{
	float: left;
	display: block;
	/*width: 60px;*/
	padding: 0 2px 20px;
	text-align: center;
}
#profile section ul li a{
	/*text-decoration: underline;*/
        font-family: Georgia,メイリオ,Helvetica,"ＭＳ Ｐゴシック",Osaka,"ヒラギノ角ゴ Pro W3";
}


/* contents 共通で使うのだぁあああ　*/

#contents_title {
	margin: 0 5%;
	padding: 20px;
	text-align: center;
	color: #FFF;
	letter-spacing:10px;
	text-indent: 10px;
	border-bottom: dashed 1px #fff;
}


#contents_title_noborder {
	margin: 0 5%;
	padding: 20px;
	text-align: center;
	color: #FFF;
	letter-spacing:10px;
	text-indent: 10px;
}

/* end */

/* @group quicknavi */

#quicknavi {
	clear: both;
	margin: 0;
	padding: 0;
}

#quicknavi_wrap {
	margin: 0 auto;
	padding: 5px 2.5% 4px 0;
	text-align: center;
	background: #ffffff;
}

#quicknavi ul {
	overflow: auto;
	width: 99%;
	margin: 0 auto;
	list-style-type: none;
}

#quicknavi ul li {
	float: left;
	width: 33%;
	text-align: center;
	border-left: 1px solid #000;
	border-bottom: 1px solid #000;
}

#quicknavi ul li a {
	display: block;
	font-size: 90%;
	color: #fff;
	font-weight: bold;
	letter-spacing: 0.1em;
	text-decoration: none;
	background: #141414 url(../img/quicknavi_bg.gif);
}

#quicknavi ul li:first-child {
}

#quicknavi ul li:first-child a {
}

#quicknavi ul li:last-child {
}

#quicknavi ul li:last-child a {
}



.btn {
	float: left;
	background: -moz-linear-gradient(top,#FFF 0%,#EEE);
	background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE));
	width: 45%;
	margin:0 2.5%;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-moz-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-webkit-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
}
.btn a{
	display: block;
	padding: 8px 0;
	font-family:serif;
	font-weight: bold;
	color:#000;
}



.btn_50 {
	float: center;
	background: -moz-linear-gradient(top,#FFF 0%,#EEE);
	background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE));
	width: 50%;
	margin: 10px auto;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-moz-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-webkit-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
}
.btn_50 a{
	display: block;
	padding: 8px 0;
	font-family:serif;
	font-weight: bold;
	color:#000;
	text-align:center;
}

.btn_50 {
	float: left;
	background: -moz-linear-gradient(top,#FFF 0%,#EEE);
	background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE));
	width: 45%;
	margin: 10px 5px;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-moz-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-webkit-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
}
.btn_50 a{
	display: block;
	padding: 8px 0;
	font-family:serif;
	font-weight: bold;
	color:#000;
	text-align:center;
}


/* @end */

/* login_page */

.login_page {
	margin:20px auto;
	text-align: center;
}


/* regist_page */

#regist_page_attention {
	line-height:20px;
	margin: 0 0 20px;
}

.h1 {
	font-size: 120%;
	text-align: left;
	font-weight: bold;
	margin: 10px 0 0;
}

/* @group cat_pages */

#cat_pages {
	margin: 0;
	padding: 0;
	text-align: center;
}

#cat_pages .button a {
	display: block;
	margin: 5px 10px;
	padding: 10px 20px 10px 15px;
	background: #FFC0CB;
	line-height: 18px;
	color: #fff;
	text-align: center;
	font-size: 90%;
	font-weight: bold;
	letter-spacing: 0.1em;
	text-decoration: none;
}



#cat_pages .news {
	margin: 10px auto;
	padding: 0;
	font-family: sans-serif;
	line-height: 1.4em;
}

#cat_pages .news ul {
	text-align: left;
	margin: 0;
	padding: 0;
	border-bottom: 1px dotted #ffc0cb;
}

#cat_pages .news li {
	text-align: left;
	margin: 0;
	padding: 0;
	border-top: 1px dotted #ffc0cb;
	line-height: 1.4em;
	background: url(../img/r_arrow_news.png) no-repeat right center;
}

#cat_pages .news li a {
	display: block;
	color: #242424;
	text-decoration: none;
	padding: 12px 25px 12px 10px;
}

#cat_pages .simplelist {
	margin: 10px auto;
	padding: 0;
	font-family: sans-serif;
	line-height: 1.4em;
}

#cat_pages .simplelist ul {
	text-align: left;
	margin: 0;
	padding: 0;
	border-bottom: 1px dotted #242424;
}

#cat_pages .simplelist li {
	text-align: left;
	margin: 0;
	padding: 0;
	border-top: 1px dotted #242424;
	line-height: 1.4em;
	background: url(../img/r_arrow_news.png) no-repeat right center;
}

#cat_pages .simplelist li a {
	display: block;
	color: #242424;
	text-decoration: none;
	padding: 10px 25px 10px 35px;
	background: url(../img/heartlogo_r.png) no-repeat left center;
}

#cat_pages .listcontent {
	margin: 10px auto;
	padding: 0;
	font-family: sans-serif;
	line-height: 1.4em;
}

#cat_pages .listcontent img {
	float: left;
	margin: 0;
	padding: 0 10px 0 0;
}

#cat_pages .listcontent ul {
	text-align: left;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid  #99ccff;
}


#cat_pages .listcontent li {
	text-align: left;
	margin: 0;
	padding: 0;
	border-top: 1px solid  #99ccff;
	line-height: 1.4em;
	background: url(../img/r_arrow_news.png) no-repeat right center;
}



#cat_pages .listcontent li a {
	display: table;
	width: 100%;
	color: #003366;
	text-decoration: none;
	padding: 12px 25px 12px 10px;
}

#cat_pages .date {
	font-size: 78%;
	font-weight: bold;
}

#cat_pages .newscat {
	margin: 0 0.8em;
	padding: 0 0.2em;
	color: #fff;
	font-size: 76%;
	background: #000;
}

#cat_pages .newstitle {
	font-size: 90%;
}

#cat_pages p {
	margin: 0;
	padding: 0.6em 0;
}

#cat_pages .smallp {
	font-size: 80%;
	color: #333;
	line-height: 1.8em;
}

#cat_pages .link {
	text-align: center;
	margin: 0;
	padding: 0;
}

#cat_pages .link a {
	display: block;
	width: 84%;
	text-align: center;
	text-decoration: none;
	letter-spacing: 0.04em;
	margin: 24px auto;
	padding: 0.4em;
	color: #fefefe;
	font-size: 88%;
	background: #141414;
}

#cat_pages .link2 a {
	display: block;
	width: 84%;
	text-align: center;
	text-decoration: none;
	letter-spacing: 0.04em;
	margin: 24px auto;
	padding: 0.4em;
	color: #fefefe;
	font-size: 88%;
	background: -moz-linear-gradient(top,#f795ab 0%,#ee697c);
	background: -webkit-gradient(linear, left top, left bottom, from(#f795ab), to(#ee697c));
	border: 1px solid #ee697c;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-moz-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
	-webkit-box-shadow: 1px 1px 1px rgba(000,000,000,0.3);
}


#cat_pages .content {
	text-align: left;
	font-family: sans-serif;
	padding: 0.1em 1em;
	line-height: 1.6em;
}

#cat_pages .content_profile {
	text-align: left;
	font-family: sans-serif;
	margin: 14px 0 10px;
	padding: 0 1em;
	line-height: 1.6em;
}


#cat_pages .content a, #cat_pages .centercontent a {
	text-decoration: none;
}

#cat_pages .button a {
	color: #fff;
	text-decoration: none;
}

#cat_pages .centercontent {
	text-align: center;
	font-family: sans-serif;
	margin: 10px 0;
	padding: 1.4em 1em;
/*	border-bottom: 1px solid  #99ccff;*/
	line-height: 1.8em;
}

#cat_pages .photocontent {
	text-align: center;
	font-family: sans-serif;
	margin: 0 0 10px;
	padding: 1.4em 1em;
	border-bottom: 1px dotted #242424;
	line-height: 1.8em;
}

#cat_pages .imgtxt {
	font-size: 80%;
	margin: 0;
	padding: 0 0 1.4em;
	line-height: 1.6em;
}

#cat_pages .songlist {
	text-align: left;
	font-family: sans-serif;
	margin: 10px 0;
	padding: 1.4em 1em;
}

#cat_pages .chakuutalist {
	font-size: 80%;
	margin: 0;
	padding: 0 1em 1em;
	line-height: 1.6em;
}

/* @end */



/* @group support */

.selector11 {
	color: #000;
	font-weight: bold;
	margin: 10px 0;
	padding: 7px 0 7px 10px;
	border-radius: 7px;
	background:#fff6e5;
	margin: 0 auto;
}

/*
.selector12{
	background: -moz-linear-gradient(top,#f795ab 0%,#ee697c);
	background: -webkit-gradient(linear, left top, left bottom, from(#f795ab), to(#ee697c));
	border: 1px;
	color:#FFC0CB;
	border-bottom:0px solid #FFC0CB;
	padding:0px 0px 3px 5px;
	line-height:160%;
}


.selector13{
	background-color:#dcdcdc;
	color:#dcdcdc;
	border-bottom:2px solid #dcdcdc;
	padding:5px 0 5px 10px;
	line-height:160%;


#support section ol,
#support section ul{
	line-height: 50em;
}
#support section ol{
	list-style-type: decimal;
	margin: 0 auto;
	padding: 0 0 0 1.5em;
}
#support section ul{
	padding-left: 1.5em;
}

#support section ol li{
	list-style-type: decimal;
}
#support section ul li{
	list-style-type:disc;
}


 @end */


/* ------------------------ detail ------------------------ */
#news.detail article time,
#disco.detail article h1{
	display: block;
	font-weight: bold;
	border-top: solid 1px #dbdbdb;
	border-bottom: solid 1px #dbdbdb;
	background: -webkit-gradient( linear, left top, left bottom, from(#fff), to(#d7d7d7));
}
#news.detail article hgroup,
#news.detail article p{
	padding: 0 10px;
}
#news.detail article time{
	padding: 5px 0 5px 10px;
}
#news.detail article h1{
	padding: 10px 0;
}
#news.detail article h2{
	font-size: medium;
	font-weight: bold;
}
#disco.detail article h1{
	padding: 15px 0px;
	text-align: center;
}
#disco.detail article time{
	display: block;
	margin: 5px 0 0;
	text-align: center;
	font-size: small;
}
#disco.detail article div.text{
	background: #ffffff;
	padding: 1px 10px;
}
#profile section h1{
	margin: 30px;
	text-align: center;
}
#profile section p{
	padding: 1px 10px;
}
#profile section dl{
	width: 200px;
	margin: 1px auto;
	line-height: 1.5em;
}
#profile section dt{
	display: inline;
	float: left;
}
#profile section dt:after{
	content: "：";
}
/* ------------------------ figure ------------------------ */
#disco.detail article figure img,
#profile section img{
	display: block;
	border: solid 1px #a6a6a6;
	box-shadow: 2px 2px 5px #ccc;
}
#disco.detail article figure img,
#profile section figure img{
	margin: 0 auto 10px;
}
#profile section ul img{
	margin: 0 auto 5px;
}
#disco.detail article figure{
	margin: 20px 0;
	text-align: center;
}
#disco.detail article figure figcaption{
	line-height: 1.2em;
}

/* ------------------------ button ------------------------ */
nav.button_list{
	width: 180px;
	margin: 20px auto;
}
a.button{
	display: block;
	padding: .5em 0;
	/*background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e6e6e6)) !important;*/
	border: solid 1px #898989;
	border-radius: 5px;
	font-size: small;
	color: #ffffff;
        background: #555555;
        /*width: 300px;*/
        margin: 0 auto;
}

a.btn_80 {
	display: block;
	padding: .8em 0;
	border: solid 1px #898989;
	border-radius: 5px;
	font-size: small;
	color: #ffffff;
	text-align: center;
        background: #555555;
        width: 80%;
        margin: 20px auto;
}


a.button.back,
a.button.next{
	width: 80px;
	margin: 0 0 10px;
}
a.button.back{
	float: left;
	text-align: left;
}
a.button.back span{
	width: 40px;
	background: url(../img/arrow_gl.png) 10px center no-repeat;
	padding: 0 10px 0 30px;
}
a.button.next{
	float: right;
	text-align: right;
}
a.button.next span{
	display: block;
	width: 40px;
	background: url(../img/arrow_gr.png) 55px center no-repeat;
	padding: 0 30px 0 10px;
}
a.button.list{
	clear: both;
	float:none;
	text-align: center;
}
#profile.detail nav.button_list,
#fanclub nav.button_list,
#about nav.button_list{
	width: 80px;
	margin: 20px auto;
}


/* カラーボタン */
#index section.news_list nav{
	display: block;
	float: right;
	width: 70px;
	margin: 10px 10px 20px 0;
	padding: 5px 10px 5px 5px;
    -webkit-border-radius: 5px;
}
#index section.news_list nav a{
	display: block;
	padding: 5px 20px 5px 10px;
	font-size: small;
	text-align: right;
	background: url(../img/arrow_w.png) right center no-repeat;
	border: none;
}
#support a.regist{
	display: block;
	padding: .5em 0;
	margin: 20px 0;
	line-height: 1.5em;
	border-radius: 5px;
	font-size: small;
	text-align: center;
	font-weight: bold;
}

/* index.html
------------------------------------------------*/

#index section{
	clear: both;
	margin: 20px 0 0;
}
#index section h1{
	height: 40px;
	color: #fff;
}
#index section h1 span{
	display: block;
	width: 320px;
	height: 40px;
	text-indent: -9999em;
	background-position: left top;
	background-repeat: no-repeat;
}
/*
#index section h1:after{
	content:"";
	display: block;
	margin: -6px 0 0 0;
	background-image: url(../img/line.png);
	background-repeat: repeat;
	height: 6px;
}*/

/* special */

#special_img a{
	margin: 0 auto;
	text-align: center;
	display:block;
}

/* information */

.info_title{
	width: 95%;
	margin: 0 auto;
	background-color: #d6d2c7;
	text-align: center;
	font-size: 90%;
	color: #000;
	font-weight: bold;
}


.info_list ul{
	margin: 0px;
/*	border-bottom: solid 1px #a6a6a6;*/
}

.info_list ul li{
	width: 96%;
	vertical-align: middle;
	margin: 0px;
}

.info_list ul li a{
	height:60px;
	color: #fff;
	padding: 2%;
	white-space: nowrap;
	display: block;
	background: url(../img/arrow_news.png) no-repeat right center;
}

.info_list ul li img{
	float: left;
	margin: 0 5% 0 3%;
}


/*
#index section.information ul{
	margin: 1px 0 0;
}
#index section.information ul li{
	display: block;
	line-height: 3em;
	background: #ebebeb;
	border-bottom: solid 1px #a6a6a6;
}
#index section.information ul li a{
	display: block;
	color: #000;
}
#index section.information ul li a img{
	margin: 0 7px 0 10px;
}
*/


/* membermenu
#index section.membermenu h1 span{
	background-image: url(../img/title_membermenu.png);
}
#index section.membermenu ul{
	margin: 1px 0 0;
}
#index section.membermenu ul li{
	display: block;
	line-height: 3em;
	background: #ebebeb;
	border-bottom: solid 1px #a6a6a6;
}
#index section.membermenu ul li a{
	display: block;
	color: #000;
}
#index section.membermenu ul li a img{
	margin: 0 7px 0 10px;
}


div.centercontent a {
	color: #003366;
	text-decoration: underline;
}


div.centercontent {
	text-align: center;
	font-family: sans-serif;
	margin: 10px 0;
	padding: 1.4em 1em;
	line-height: 1.8em;
}
*/

/* @group decomail */

div.list_block {
    background:  repeat scroll 0 0 transparent;
/*    border-bottom: 1px solid #CCCCCC;*/
    clear: both;
    margin: 10px;
}

div.list_block h2 {
    background: #ff69b4;
    color: #000000;
    font-size: 15px;
    padding: 10px 0;
    text-align: center;
}

div.decomaillist ul {
	margin: 5px 5px 10px;
}

div.decomaillist li {
	text-align: left;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #FFFFFF;
	line-height: 1.4em;
	background: url(../img/arrow_news.png) no-repeat right center;
}

div.decomaillist li.first {
	border-top: none;
}

div.decomaillist li a {
	display: block;
	color: #fff;
	text-decoration: none;
	padding: 12px 25px 12px 10px;
}

/* support */

#index section.support ul{
	margin-left:5%;
	margin-right:5%;
}

#index section.support ul li{
	display: block;
	float: left;
	width: 50%;
	line-height: 4em;
	font-size: 75%;
	border-bottom: solid 1px #fff;
}

#index section.support ul li a{
	display: block;
	color: #fff;
}

#support_tokusyo a{
	width: 90%;
	float: left;
	margin-left:5%;
	margin-right:5%;
	line-height: 3.5em;
	font-size: 85%;
	color: #fff;
	border-bottom: solid 1px #fff;
	display:block;
}

/*
 about
------------------------------------------------*/
#about section,
#about header p{
	margin: auto 10px;
}
#about section h1{
	text-align: center;
	margin: 20px 0 0;
}
#about section h2:before{
	content:"【";
}
#about section h2:after{
	content:"】";
}
#about section h2{
	font-size: 100%;
	margin: 30px 0 10px;
}
#about section a{
	display: block;
	text-align: center;
}


/* support
------------------------------------------------*/
/*#support section{
	margin: 0 10px;
}*/
#support section h1,
#support section h2.course{
	text-align: center;
	margin: 0 0 20px;
}
#support section h2.course{
	font-size: 140%;
}
#support .caution{
	color: #555555;
}
#support a.caution{
	text-decoration: underline;
}
#support section h2{
	margin: 0 0 0;
	text-align: center;
	font-weight: bold;
	font-size: 120%;
}

#support article.faq{
	margin: 0 0 15px;
}
#support section.kiyaku p,
#support article.faq p{
	margin: 0;
}
#support hr{
	border: 0;
	background-image: url(../img/line.png);
	background-position: left top;
	background-repeat: repeat-x;
	margin: 10px -10px;
	height: 6px;
}

#support_nav li{
	color: #898989;
	float: left;
	width: 50%;
	font-size: 75%;
	line-height: 52px;
	height: 52px;
	vertical-align: middle;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: #dedede;
}


#support_nav ul{
	margin:5px;
}


h5 {
	font-size: 100%;
	font-weight: bold;
	margin: 0;
	padding: 0;
	color: #242424;
	line-height: 1.8em;
	letter-spacing: 0.1em;
}

#footer{
 clear: both;
 background-color: #020202;
 background-repeat: repeat-x;
 /*width: 320px;
 height: 52px;*/
}

#footer li{
 color: #ffffff;
 font-family: Georgia;
 font-size: 10px;
 text-align: center;
 list-style: none;
 padding: 16px 0px 16px 0px;
}

.menu_title{
 font-family: Georgia;
 font-size: 17px;
 height: 52px;
 line-height: 52px;
 vertical-align: middle;

}

#support_nav a:link {/*未訪問のリンク*/
    color: #898989;
    text-decoration: none;
}

#support_nav a:visited {/*訪問後のリンク*/
    color: #898989;
    text-decoration: none;
}

#support_nav a:hover {/*マウスオン*/
    color: #898989;
    text-decoration: none;
}

#schedule
nav.main_contents li a {
    padding: 5px 6px;
}

#register_btn{
 width: 300px;
 margin: 0 auto;
}

#news_contain{
 margin: 0px 0px 10px 0px;
}

#qdn_contain{
 margin: 0px 0px 10px 0px;
}

#quicknavi_btn{
 width: 300px;
 margin: 0 auto;
}

/*.btn{
  -moz-box-shadow: -1px -1px 1px rgba(000,000,000,0.3),1px 1px 1px rgba(255,255,255,1);
	-webkit-box-shadow: -1px -1px 1px rgba(000,000,000,0.3),1px 1px 1px rgba(255,255,255,1);
	width: 240px;


}
*/


section.attetion_area {
	background-color: #ffcced;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 0 0 2px #CCCCCC;
/*	width: 90%;	*/
	margin: 5px;
	padding: 5px;
}

section.attetion_area p{
	margin: 5px;
}

section.attetion_area ul,
section.attetion_area li{
	list-style: inside;
}

section.bdmail_area {
	background-color: #fff;
	border: 2px solid #999;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 0 0 2px #CCCCCC;
	width: 90%;
	margin: 0 auto;
	padding: 5px;
}

section.help_area {
	background-color: #fff;
	border: 2px solid #999;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 0 0 2px #CCCCCC;
	width: 90%;
	margin: 0 auto 10px;
	padding: 5px;
}

section.help_area h2{
	font-weight: bold;
	border-bottom: 1px solid #999;
	color: #EB6088;
}


section.regist_area {
	background-color: #fff;
	border: 2px solid #999;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 0 0 2px #CCCCCC;
	width: 90%;
	margin: 0 auto 10px;
	padding: 5px;
	clear: both;
}

section.regist_area h2{
	font-weight: bold;
	background-color: #EB6088;
	border-bottom: 2px solid #999;
	box-shadow: 0 0 2px #CCCCCC;
/*	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-left: 2px solid #EB6088;*/
	color: #fff;
	padding: 5px;
}

/* 20191101 早川追加　*/
.qd_nav ul{
	margin:5px;
}
.qd_nav li{
	color: #000000;
	float: left;
	width: 50%;
	font-size: 75%;
	line-height: 52px;
	height: 52px;
	vertical-align: middle;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: #dedede;
}

.qd_nav li a:link {/*未訪問のリンク*/
    color: #000000;
    text-decoration: none;
}

.qd_nav li a:visited {/*訪問後のリンク*/
    color: #000000;
    text-decoration: none;
}

.qd_nav li a:hover {/*マウスオン*/
    color: #000000;
    text-decoration: none;
}


/* ★★★★★SPECIALページ★★★★★ */

section.special_page {
	margin: 20px 10px;
}

section.special_page h1{
	text-align: center;
	color: #EB6088;
	font-size: 130%;
}


.center_center {
	text-align: center;
	margin: 0 auto;
}

/* ★★★★★Q&A回答編ページ★★★★★ */
ul.qaanserlist {
	clear: both;
	margin: 0;
	padding: 0;
	border-top: 4px double #E4E4E4;
}
ul.qaanserlist li {
	background: url(../img/arrow_news.png) no-repeat right center;
	display: block;
	border-bottom: 1px solid #E4E4E4;
	min-height: 44px;
}
ul.qaanserlist li a {
	display: block;
	min-height: 44px;
	color: #555555;
	font-size: 100%;
	padding: 10px 20px 10px 0;
}

.questiondtl {
	clear: both;
	padding: 10px 0 0;
	margin-bottom: 10px;
}
.answerdtl {
	clear: both;
	padding: 10px 0 20px;
}
.questiondtl h2 ,
.answerdtl h2 {
	color: #EB6088;
	clear: both;
	font-size: 110%;
	font-weight: bold;
	border-bottom: 2px dotted #E4E4E4;
}
.questiondtl h2:first-letter,
.answerdtl h2:first-letter{
	float: left;
	font-size: 2em;
}
p.from {
	text-align: right;
	font-size: 90%;
}

/* ★★★★★ LIVE SCHEDULE部分★★★★★ */
#qdn_contain ul,
#liveschedule ul {
	margin: 5px auto;
	text-align: center;
}
#qdn_contain ul li,
#liveschedule ul li {
	background: none repeat scroll 0 0 #898989;
	border: 1px solid #696969;
	border-radius: 15px 15px 15px 15px;
	display: block;
	float: left;
	height: 40px;
	line-height: 40px;
	width: 45%;
	margin: 5px 5px;
}
#qdn_contain ul li a,
#liveschedule ul li a {
	color: #FFFFFF;
	display: block;
	font-size: 90%;
	font-weight: bold;
	letter-spacing: 0.1em;
	line-height: 40px;
	text-align: center;
}

/*　カテゴリ背景色追加など　*/

.cat_back{
	font-size:x-small;
	margin-left:5px;
	width:20px;
	height:10px;
	background-color:#EB6088;
	color:#000;
}

/*　早川追加　*/

dl.kiyaku{
	margin:10px 5px;
}

dl.kiyaku dt{
	margin:20px 0px 5px 0px;
	font-weight:bold;
}

dl.kiyaku dd{
	margin:5px;
	font-size:95%;
}

dl.kiyaku dd ul{
	margin:5px;
}

dl.kiyaku dd ul li{
	margin:3px auto;
	font-size:90%;
}

// バースデーメール
.birthday-img {
	padding: 0 20px;

	img {
		width: 100%;
		height: auto;
	}
}

.birthday-text {
	padding: 0 20px;
}