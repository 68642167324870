@charset "utf-8";
/* CSS Document */


/*------------------------ テーマカラー ------------------------ */
.menu_title,
.line,
.arrow_color,
footer:before,
body.page header:before,
body.page header h1:after,
#index section h1:after,
#support hr{
	// background-color: #000000;
}
#news.detail article time{
	color: #898989;
}

/* グラデーション 
#index header nav.main_contents{
	background: #9b020d;
}
#index header nav.main_contents h2{
	background:  url(../img/title_menu.png) center center no-repeat,
				-webkit-gradient(linear, left top, left bottom, from(#dc676f), to(#9b020d));
}
#disco.page nav.category li.single{
	background: url(../img/title_disco_single.png) center center no-repeat,
				-webkit-gradient(linear, left top, left bottom, from(#dc676f), to(#9b020d));
}
#disco.page nav.category li.album{
	background: url(../img/title_disco_album.png) center center no-repeat,
				-webkit-gradient(linear, left top, left bottom, from(#dc676f), to(#9b020d));
}
#disco.page nav.category li.dvd{
	background: url(../img/title_disco_dvd.png) center center no-repeat,
				-webkit-gradient(linear, left top, left bottom, from(#dc676f), to(#9b020d));
}
#disco.page nav.category li.other{
	background: url(../img/title_disco_other.png) center center no-repeat,
				-webkit-gradient(linear, left top, left bottom, from(#dc676f), to(#9b020d));
}
*/

/* ------------------------ アクセントカラー ------------------------ */
#index header nav.header_nav li.about a,
#news.detail article h1,
#profile section ul li a,
#fanclub section h1,
#support section article h2{
	color: #555555;
}
#index header nav.header_nav li.about a img{
	background: #ff4d84;
}

/* ボタン */
#index section.news_list nav,
#support a.regist{
	background: -webkit-gradient(linear, left top, left bottom, from(#ff96b7), to(#992f50));
}
#index section.news_list nav a,
#support a.regist{
	color: #fff;
}

/* arrow */
section.news_list a article,
section.schedule_list a article,
section.discography_list a article
section.information a article{
	background-image: url(../img/arrow_news.png); /* >の色を変更する場合は、画像を差し替えてください。 */
}

section.news_list article a {
	background: url(../img/arrow_news.png) right center no-repeat;
}