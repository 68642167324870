/* ========================================

    nav
        ナビゲーションのパーツ

    INDEX
        - global
        - trigger
        - sub
        - pnkz
        - members menu

======================================== */

/* ========================================
    global
======================================== */
.menu {
    color: white;
    transition: 0.3s;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    color: #fff;
    visibility: hidden;
    background: #1f1f1f;
    opacity: 0;
    transition: visibility .6s cubic-bezier(.165,.84,.44,1),opacity .6s cubic-bezier(.165,.84,.44,1);
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.js-active {
        opacity: 1;
        visibility: visible;
        
    }

    &__bg{
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
    }
    &-list{
        z-index: 2;
        &__item{
            line-height: 2;
            a{
                font-family: Georgia, sans-serif;
                display: block;
                width: 100%;
                height: 100%;
                font-size: 1.2rem;
                color: white;
            }
        }
    }
}


/* ========================================
    trigger
======================================== */
// 以下から持ってきたハンバーガーメニュー
// https://www.nxworld.net/tips/12-css-hamburger-menu-active-effect.html
%trigger-base {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
}

.trigger-menu {
    @extend %trigger-base;
    $width_size: 45px;
    $height_size: 40px;
    $border_size: 2px;
    $padding_size: 10px;
    $middle_position: #{($height_size / 2) - ($border_size / 2) - ($padding_size)};
    width: $width_size;
    height: $height_size;
    padding: $padding_size;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    background-color: rgba($C_BLACK, 0.3);
    

    &__inner {
        position: relative;
        width: 100%;
        height: 100%;
    }

    span {
        @extend %trigger-base;
        position: absolute;
        left: 0;
        width: 100%;
        height: $border_size;
        background-color: white;

        &:nth-of-type(1) {
            top: 0;
        }
            
        &:nth-of-type(2) {
            top: $middle_position;
        }
            
        &:nth-of-type(3) {
            bottom: 0;
        }
    }

    &.js-active {
        span {
            &:nth-of-type(1) {
                transform: translateY(#{$middle_position}) rotate(-45deg);
            }
            
            &:nth-of-type(2) {
                opacity: 0;
            }
            
            &:nth-of-type(3) {
                transform: translateY(-#{$middle_position}) rotate(45deg);
            }
        }
    }
}


/* ========================================
    sub
======================================== */
.sub-menu {
    
}


/* ========================================
    pnkz
======================================== */
.pnkz {
    
}

/* ========================================
    members menu
======================================== */
.members-menu {

}